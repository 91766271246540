<template>
  <div>
    <div class="vx-row">
      <feather-icon
        v-if="EnglishLan"
        @click="back()"
        icon="ChevronLeftIcon"
        style="width: 30px; cursor: pointer"
      >
      </feather-icon>
      <feather-icon
        v-else
        @click="back()"
        icon="ChevronRightIcon"
        style="width: 30px; cursor: pointer"
      >
      </feather-icon>
      <p class="text-xl">
        {{ $t("Setting") }}
      </p>
    </div>
    <div class="w-full justify-center">
      <div class="w-full vx-row m-2 justify-between">
        <h1>
          <p>{{ $t("Additionalinformation") }}</p>
        </h1>
        <!-- @click="$router.push('/HospitalProfile/'+data.ID)" -->

        <h4
          class="text-primary"
          @click="$router.push('/ChangePassword')"
        >
          <u>{{ $t("EditPassword") }}</u>
        </h4>
      </div>
      <div class="vx-row w-full con-img justify-center">
        <img
          v-if="UserUpdetedData.ProfileImagePath"
          :src="baseURL + UserUpdetedData.ProfileImagePath"
          alt="user-img"
          width="70"
          height="70"
          class="rounded-full shadow-md cursor-pointer block m-4"
        />

        <img
          v-else
          :src="baseURL + 'Uploads/docotrGeneral.jpg'"
          alt="user-img"
          width="70"
          height="70"
          class="rounded-full shadow-md cursor-pointer block m-4"
        />
        <div class="position: absolute py-12 w-1">
          <input
            type="file"
            class="hidden"
            ref="uploadImgInput"
            @change="updateCurrImg"
            accept="image/*"
            @on-success="successUpload"
          />

          <vs-avatar
            @click="$refs.uploadImgInput.click()"
            color="primary"
            icon-pack="feather"
            icon="icon-upload"
            class="m-5"
          />
        </div>
      </div>

      <div class="vx-row w-full justify-center">
        <div div class="vx-row w-full justify-center">
          <vs-input
            data-vv-validate-on="blur"
            name="Name"
            class="w-full xl:w-5/12 sm:w-1/4 m-3 mt-3"
            icon-no-border
            label-placeholder="Name"
            :placeholder="$t('Name')"
            v-model="UserUpdetedData.NameEN"
            :icon-after="true"
          />
        </div>
        <div div class="vx-row w-full justify-center">

            <!-- <VuePhoneNumberInput :placeholder="$t('PhoneNumber')" @update="acceptNumber" v-model="UserUpdetedData.Phone" :default-country-code="defaultPhoneData.countryCode"  /> -->
            <VuePhoneNumberInput :placeholder="$t('PhoneNumber')"  @update="acceptNumber" v-model="UserUpdetedData.Phone" :default-country-code="defaultPhoneData.countryCode"   />

          <!-- <vs-input
            data-vv-validate-on="blur"
            name="PhoneNumber"
            class="w-full xl:w-5/12 sm:w-1/4 m-3 mt-3"
            icon-no-border
            label-placeholder="PhoneNumber"
            :placeholder="$t('PhoneNumber')"
            v-model="UserUpdetedData.Phone"
            :icon-after="true"
          /> -->
        </div>

        <div class="vx-row w-full justify-center">
          <vs-input
            v-validate="'required|email'"
            data-vv-validate-on="blur"
            name="Email"
            class="w-full xl:w-5/12 sm:w-1/4 m-3 mt-3"
            icon-no-border
            label-placeholder="Email"
            :placeholder="$t('Email')"
            v-model="UserUpdetedData.Email"
            :icon-after="true"
          />
        </div>
        <div class="vx-row w-full justify-center">
          <v-select
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            data-vv-validate-on="blur"
            label="Name"
            name="PreferredLanguage"
            class="w-full xl:w-5/12 sm:w-1/4 m-3 mt-3"
            v-model="UserUpdetedData.PreferredLanguageID"
            :options="languages"
            label-placeholder="PreferredLanguage"
            :placeholder="$t('PreferredLanguage')"
            :icon-after="true"
            :reduce="(ID) => ID.ID"
          />
        </div>

        <div class="vx-row w-full justify-center">
          <div class="w-full ml-10">
            <h1>
              <p>{{ $t("YourNotification") }}</p>
            </h1>
          </div>
          <div div class="vx-row w-full justify-center">
            <h4 class="xl:w-5/12 sm:w-1/4 m-3 mt-3">
              {{ $t("onlineappointmentNotification") }}
            </h4>

            <vs-switch v-model="Model.IsOnlineAppointmentNotification" />
          </div>

          <div div class="vx-row w-full justify-center">
            <h4 class="xl:w-5/12 sm:w-1/4 m-3 mt-3">
              {{ $t("SergeryNotify") }}
            </h4>

            <vs-switch v-model="Model.IsSurgeryDateNotification" />
          </div>

          <div div class="vx-row w-full justify-center">
            <h4 class="xl:w-5/12 sm:w-1/4 m-3 mt-3">
              {{ $t("OnlineReleas") }}
            </h4>
            <vs-switch v-model="Model.IsOnlineAppointmentpaymentIsDue" />
          </div>

          <div div class="vx-row w-full justify-center">
            <h4 class="xl:w-5/12 sm:w-1/4 m-3 mt-3">
              {{ $t("SergeryPayment") }}
            </h4>

            <vs-switch v-model="Model.IsSurgeryPaymentDue" />
          </div>
        </div>

        <div class="vx-row w-full justify-center">
          <div class="w-full ml-10">
            <h1>
              <p>{{ $t("permissions") }}</p>
            </h1>
          </div>
          <div div class="vx-row w-full justify-center">
            <h4 class="xl:w-5/12 sm:w-1/4 m-3 mt-3">
              {{ $t("Promotion") }}
            </h4>

            <vs-switch v-model="Model.IsReceiveOffersAndPromotions" />
          </div>
          <div div class="vx-row w-full justify-center">
            <h4 class="xl:w-5/12 sm:w-1/4 m-3 mt-3">
              {{ $t("NewsMonthely") }}
            </h4>

            <vs-switch v-model="Model.IsReceiveMonthlyNewsletter" />
          </div>
          <div div class="vx-row w-full justify-center">
            <h4 class="xl:w-5/12 sm:w-1/4 m-3 mt-3">
              {{ $t("InfoPackage") }}
            </h4>

            <vs-switch v-model="Model.IsReceiveInformationPackages" />
          </div>
          <div div class="vx-row w-full justify-center">
            <h4 class="xl:w-5/12 sm:w-1/4 m-3 mt-3">
              {{ $t("InfoHospital") }}
            </h4>

            <vs-switch
              v-model="
                Model.IsReceiveInformationRecentlyAddedHospitalsAndDoctors
              "
            />
          </div>
        </div>

        <div class="w-full">
          <div class="justify-right">
            <vs-button
              type="filled"
              @click="SaveSetting()"
              style="float: right"
              >{{ $t("Save") }}</vs-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/axios.js";
import { domain, mainSetting } from "@/gloabelConstant.js";
import modulePatient from "@/store/Patient/modulePatient.js";
import moduleLanguage from "@/store/settings/language/moduleLanguage.js";
import VuePhoneNumberInput from 'vue-phone-number-input';


export default {
  components: {VuePhoneNumberInput},
  data() {
    return {
      defaultPhoneData:{
        countryCode : ""
      },
      phonevalue:'',
      EnglishLan: false,
      baseURL: domain,
      UserData: {
        ProfileImagePath: "",
      },
      UserUpdetedData: {
        ProfileImagePath: "",
        PreferredLanguageID: 0,
        Email: "",
        Phone: "",
        NameEN: "",
        preferredcountries:""
      },
      Model: {},
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",
      options: [],
      radios1: "luis",
      date: null,
    };
  },
  props: {},
  computed: {
    languages() {
      return this.$store.state.LanguageList.languages;
    },
  },
  // watch: {
  //   Model () {
  //     debugger;
  //     this.defaultPhoneData.countryCode = this.countries.find((x) => x.ID === this.Model.CountryID).IsoCode
  //   }
  // },
  methods: {
    back() {
      this.$router.go(-1);
    },
     acceptNumber(value) {
      debugger;
      if (value.countryCode !="US")
      {
        this.phonevalue = value.e164;
        this.defaultPhoneData.countryCode=value.countryCode;
      }else{
        this.phonevalue = value.e164;
        this.defaultPhoneData.countryCode;
      }

   //  this.Model.CountryID = this.countries.find((x) => x.IsoCode === value.countryCode).ID
    },

    SaveSetting() {
      debugger;
      this.Model.Phone = this.phonevalue;
      this.$vs.loading();
      if (this.Model.ID) {
        this.$store
          .dispatch("patientList/UpdateUserNotificationSetting", this.Model)
          .then((res) => {
            debugger;
            window.showSuccess(res.data.Message);
            this.$vs.loading.close();
            this.savePatientModel();
          })
          .catch((err) => {
            window.showError(err.response.data.message);
            this.$vs.loading.close();
          });
      } else {
        this.$store
          .dispatch("patientList/AddUserNotificationSetting", this.Model)
          .then((res) => {
            window.showSuccess(res.data.Message);

            this.$vs.loading.close();

            this.savePatientModel();
          })
          .catch((err) => {
            window.showError(err.response.data.message);
            this.$vs.loading.close();
          });
      }
    },
    savePatientModel() {
      this.$vs.loading();
      this.UserData.Phone = this.phonevalue;
      this.UserData.NameEN = this.UserUpdetedData.NameEN;
    //  this.UserData.Phone = this.UserUpdetedData.Phone;
      this.UserData.Email = this.UserUpdetedData.Email;
      this.UserData.ProfileImagePath = this.UserUpdetedData.ProfileImagePath;
      this.UserData.PreferredLanguageID =
        this.UserUpdetedData.PreferredLanguageID;

      this.$store
        .dispatch("patientList/UpdatePatient", this.UserData)
        .then((res) => {
          console.error(res);
          this.$vs.loading.close();
          window.showAddSuccess();
          this.$store.state.AppActiveUser.Patient = this.UserData;
          localStorage.setItem(
            "userInfo",
            JSON.stringify(this.$store.state.AppActiveUser)
          );

          this.back();

          // this.initPatientModelValues();
        })
        .catch((err) => {
          window.showError(err.response.data.message);
          this.$vs.loading.close();
          console.error(err);
        });
    },
    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur",
      });
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        let data = new FormData();
        data.append("file", input.target.files[0]);

        axios.post(this.uploadUrl, data,{
          headers: {
              "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
          this.UserUpdetedData.ProfileImagePath = response.data;
        });
      }
    },
  },
  created() {
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" ||
      localStorage.getItem("SaveLang") == null
        ? true
        : false;

    if (!moduleLanguage.isRegistered) {
      this.$store.registerModule("LanguageList", moduleLanguage);
      moduleLanguage.isRegistered = true;
    }

    this.$store.dispatch("LanguageList/GetAllLanguages");
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }

    this.UserData = this.$store.state.AppActiveUser.Patient;
    this.UserUpdetedData.NameEN = this.UserData.NameEN;
    this.UserUpdetedData.Phone = this.UserData.Phone;
    this.UserUpdetedData.Email = this.UserData.Email;
    this.UserUpdetedData.ProfileImagePath = this.UserData.ProfileImagePath;
    this.UserUpdetedData.PreferredLanguageID =
      this.UserData.PreferredLanguageID;

    debugger;
    this.$store
      .dispatch("patientList/GetUserNotificationSettings")
      .then((res) => {
        debugger;
        if (res.data.Data) this.Model = res.data.Data;
      });
  },
};
</script>
<style>
.textfont {
  font-family: "futuraMedium";
}
</style>
